.animate-breathe {
  animation: breathe 4s linear infinite;
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-coin {
  animation: coin 2s linear infinite;
}

@keyframes coin {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.animate-flip {
  animation: flip 2s linear infinite;
}

@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(360deg);
  }
}
