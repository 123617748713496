@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:not(.tox *) {
  font-family: var(--inter-font);
}

.w-inherit {
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 3px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #1e40af;
}

input[type='radio']:checked {
  border-color: #1e40af;
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.transition-width {
  transition: width 0.3s ease-in-out;
}

.doc-editor.doc-editor-modal .tox.tox-tinymce .tox-edit-area {
  height: calc(100vh - 20.45rem) !important;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.custom-border-color {
  border-color: #cbd5e1;
}

.sidebar-bg-gradient-1 {
  background: linear-gradient(90deg, #0430af 0%, #0d2056 153.72%);
}

.aira-bg-gradient {
  background: linear-gradient(90deg, #1e3a8a 0%, #5779d8 100%) !important;
}
.dashboard-bg-gradient {
  background: linear-gradient(180deg, #ede9f4 0%, #ddeaed 100%) !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
}

.sidebar-erica-bg-gradient-1 {
  border-radius: 6.25rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(90deg, rgba(178, 185, 231, 0.45) 0%, rgba(218, 226, 252, 0.6) 100%);
  box-shadow: -6px -2px 50px 0px rgba(150, 148, 255, 0.05), -7px -4px 50px 0px rgba(187, 8, 249, 0.1);
}

.sidebar-erica-dark-bg-gradient-1 {
  border-radius: 6.25rem;
  border: 1px solid #8b97bc;
  background: linear-gradient(90deg, #183178 0%, #121e40 100%);
  box-shadow: -6px -2px 50px 0px rgba(150, 148, 255, 0.05), -7px -4px 50px 0px rgba(187, 8, 249, 0.1);
}
.sidebar-erica-dark-bg-gradient-2 {
  border-radius: 30894080rem;
  background: conic-gradient(
    from -83.04deg at 50% 50%,
    rgba(237, 14, 202, 0.8) 0%,
    rgba(255, 101, 230, 0.8) 21.35416865348816%,
    rgba(109, 106, 255, 0.8) 35.9375%,
    rgba(139, 34, 122, 0.8) 56.99999928474426%,
    rgba(82, 193, 255, 0.8) 77.99999713897705%,
    rgba(237, 14, 202, 0.8) 100%
  );
  transform: scaleX(-1);
  filter: blur(25px);
}
.sidebar-erica-bg-gradient-2 {
  border-radius: 30894080rem;

  background: conic-gradient(
    from -83.04deg at 50% 50%,
    rgba(105, 4, 255, 0.6) 0%,
    rgba(33, 75, 211, 0.6) 76.87500178813934deg,
    rgba(109, 106, 255, 0.6) 129.375deg,
    rgba(139, 34, 122, 0.6) 205.19999742507935deg,
    rgba(82, 193, 255, 0.6) 280.7999897003174deg,
    rgba(105, 4, 255, 0.6) 100%
  );
  transform: scaleX(-1);
  filter: blur(25px);
}

.sidebar {
  box-shadow: 0px 0px 16px 0px rgba(96, 165, 250, 0.16);
}
.dashboard-modal-shadow {
  box-shadow: 0px 4px 12px 0px rgba(30, 58, 138, 0.1) !important;
}
.premium-bg-gradient {
  border-radius: 0.9375rem;
  border: 1px solid #1c3479;
  background: linear-gradient(180deg, #143db5 0%, #0a142f 100%);
}
.premium-text-gradient {
  background: linear-gradient(90deg, #ecd373 4.29%, #fff3db 35.98%, #f5d738 97.5%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#headlessui-portal-root > [data-headlessui-portal]:not(:last-child) {
  visibility: hidden;
}

/* Custom styles */
@import url('./fonts.css');
@import url('./animations.css');

#sentry-feedback {
  --background: #ffffff;
  --border: 1px solid #1e3a8a;
  --foreground: #1e3a8a;
  --submit-background: #1e3a8a;
  --submit-background-hover: #1e40af;
  --input-outline-focus: none;
  .widget__actor {
    svg {
      color: #1e3a8a;
    }
  }
}

.newTableTd {
  border: solid 1px #e5e7eb;
  border-style: solid none;
}
.newTableTd:first-child {
  border-left-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.newTableTd:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.newTableTh:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.newTableTh:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.footer-box-shadow {
  box-shadow: -4px -2px 10px 0px rgba(166, 166, 166, 0.15);
}

.Toastify__toast-container--top-right:has(.customToast) {
  width: auto;
}

.animateAI-gradient {
  animation: animateAIBg 2s linear infinite;
  background-image: linear-gradient(90deg, #f0bbe9, #9ccaff, #f0bbe9, #9ccaff);
  background-size: 300% 100%;
}
@keyframes animateAIBg {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
